
































import Vue from "vue"
import { TelegramNotification } from "@/store/types";

export default Vue.extend({
  name: "NotificationsTable",

  props: {
    items: { type: Array, default: null },
  },

  data: () => ({
    headers: [
      {
        text: "Imagem",
        value: "image",
        align: "start"
      },
      {
        text: "Mensagem",
        value: "message",
        align: "start"
      },
      {
        text: "Envio",
        value: "send_in",
        align: "center"
      },
      {
        text: "Status",
        value: "status",
        align: "center"
      },
      {
        text: "Tipo",
        value: "type",
        align: "center"
      },
      {
        text: "Ações",
        value: "actions",
        width: "80",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  methods: {
    onCopy(item: TelegramNotification): void {
      this.$emit("onCopy", item)
    },
  }
})
