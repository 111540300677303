export interface RootState {
  version: string
}

export interface OffersState {
  activeOffers: Array<Offer>
  lightningOffers: Array<Offer>
}

export interface CategoryState {
  activeCategories: Array<Category>
  inactiveCategories: Array<Category>
}

export interface CollectionsState {
  collections: Array<Collection>
}

export interface StoriesState {
  stories: Array<Story>
}

export interface Offer {
  id?: string
  category?: {
    name: string
  }
  link: string
  name: string
  sku: string
  price: number
  priceFrom?: number
  store: {
    name: string
    thumbnail?: string
  }
  thumbnail: string
  active: Boolean
  featured: Boolean
  daily: Boolean
  notified_on_telegram: Boolean
  notified_on_messaging: Boolean
  notified_on_whatsapp: Boolean
  locked: Boolean
  updatedAt: Date
  expiresIn: string
}

export interface Recommendation {
  id?: string
  active: boolean
  benefits: string
  brand: string
  description: string
  howToRedeem: Array<string>
  icon: string
  link: string
  primaryColor: string
  secondaryColor: string
  title: string
  slogan: string
  rules: Array<string>
  rulesLink: string
}

export interface Category {
  id: number
  docId: string
  active: string
  description: string
  icon: string
  title: string
}

export interface OfferBySKU {
  id?: string
  sku: string
  telegram: boolean
  notification: boolean
  whatsapp: boolean
  facebook: boolean
  event_date: Date
}

export interface Collection {
  id?: string
  name: string
  picture: string
  rank: number
  active?: boolean
  created_at?: Date
  updated_at?: Date
}

export interface User {
  id?: string
  username: string
  full_name: string
  profile_pic_url: string
}

export interface ImageVersion {
  width: number
  height: number
  url: string
}

export interface VideoVersion {
  // "id": "668915527665868v"
  // "type": 101,
  width: number
  height: number
  url: string
}

// FIXME mudar de lugar
export enum mediaType {
  Image = "IMAGE",
  Gif = "GIF",
  Video = "VIDEO"
}

export interface Story {
  id?: string
  collection_id: string
  media_type: mediaType
  story_duration_secs: number
  expiring_days: number
  expiring_at: number
  seen_at?: number
  disable_share: boolean
  disable_likes: boolean
  disable_comments: boolean
  is_paid_partnership: boolean
  has_product: boolean // FIXME é necessário?
  has_link: boolean // FIXME é necessário?
  has_video: boolean
  user: string | User
  product: string | Offer | null
  link: string
  image_versions: Array<ImageVersion>
  original_width?: number
  original_height?: number
  video_codec?: string
  video_versions: Array<VideoVersion>
  created_at?: Date
  updated_at?: Date
}


export interface UploadMetadata {
  contentType: string,
  size: number
}

export interface ImageUpload {
  folder: string
  filename: string
  metadata: UploadMetadata,
  file: Blob | Uint8Array | ArrayBuffer
}

// FIXME mudar de lugar
export enum telegramNotificationSendInTypes {
  Chat = "CHAT",
  Channel = "CHANNEL"
}

export interface TelegramNotificationState {
  notifications: Array<TelegramNotification>
}

export interface TelegramNotification {
  id?: string
  image: string
  message: string
  send_in: string | telegramNotificationSendInTypes,
  status: string
  type: string
  created_at?: Date
}

export interface TelegramChannelState {
  channels: Array<TelegramChannel>
}

export interface TelegramChannel {
  id?: string
  active: boolean
  chat_id: string
  link: string
  status: string
  type: string
  title: string
  uid: string
  username: string
  created_at?: Date
  updated_at?: Date
}

export interface CodesState {
  codes: null | Codes
}

export interface Codes {
  id: string
  codes: string
}

export interface EliseStatsState {
  stats: Array<EliseStats>
}

export interface EliseStats {
  id?: string
  clients: number
  type: string
  users: number
  created_at?: Date
}

export interface ImgDimensions {
  width: number
  height: number
}